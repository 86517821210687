import { useMemo, useState } from "react";

import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../../assets/backgrounds/jumbotron-bg.png";
import section4Bg from "../../../assets/pages/international/blog/section-4-bg.png";

import { INSIGHT_INFO_BLOG } from "../../../constants/actions";
import { BANK_HOLIDAYS, BLOG } from "../../../router/constants";
import { blogArticles } from "../../../data/blog";
import { getMargin } from "../../../helpers/helpers";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { BlogArticleCard } from "../../../components/BlogArticleCard/BlogArticleCard";
import { Pagination } from "../../../components/Pagination/Pagination";
import { BlogFilter } from "../../../components/BlogFilter/BlogFilter";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { Section } from "../../../components/Section/Section";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading2 } from "../../../components/Typography/Heading2";
import { Heading4 } from "../../../components/Typography/Heading4";
import { Heading5 } from "../../../components/Typography/Heading5";
import { DownloadBtn } from "../../../components/DownloadBtn/DownloadBtn";
import { ReactComponent as Filter } from "../../../assets/icons/filter.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../../constants/constants";

import styles from "./Blog.module.scss";

export const Blog = () => {
  const [filters, setFilters] = useState({
    country: null,
    category: null,
    date: null,
  });

  const handleFilterUpdate = updatedFilters => {
    setFilters(updatedFilters);
  };

  const blogArticleCards = useMemo(() => {
    const currentFilters = Object.fromEntries(
      Object.entries(filters).filter(([, v]) => v !== null)
    );

    const articles = Object.keys(currentFilters).length
      ? blogArticles.filter(article => {
          for (const key in currentFilters) {
            if (!article[key] || article[key] !== currentFilters[key]) {
              return false;
            }
          }
          return true;
        })
      : blogArticles;

    return articles.map(article => (
      <Col key={article.id} md={4} className="mb-3" xs={12}>
        <BlogArticleCard
          img={article.imgs[0]}
          text={article.dateTitle}
          category={article.category}
          title={article.cardTitle}
          url={article.link ? article.link : `${BLOG}/${article.id}`}
          externalLink={article.link && true}
        />
      </Col>
    ));
  }, [blogArticles, filters]);

  const windowSize = useWindowSize().width;

  const menuStyles = {
    bmMenuWrap: {
      position: "fixed",
      top: "-2rem",
      right: windowSize < 576 ? "-2rem" : getMargin(windowSize),
      height: "100%",
      minHeight: "100vh",
    },
  };

  return (
    <Trackable
      interfaceId={INSIGHT_INFO_BLOG.INTERFACE_ID}
      loadId={INSIGHT_INFO_BLOG.ON_LOAD}
    >
      <Section bgImg={jumbotronBg} style={{ paddingTop: "2rem" }}>
        <ScrollAnimation
          animateIn={ANIMATIONS.FADE_IN_UP}
          delay={DEFAULT_ANIMATION_DELAY}
          animateOnce={true}
          style={{ width: "100%", zIndex: 1 }}
        >
          <Col md={12}>
            <Heading2>Blog</Heading2>
            <Heading5>
              Get the latest on everything DPD & International
            </Heading5>
          </Col>

          {windowSize <= 767 ? (
            <Menu
              customBurgerIcon={
                <Container className="d-flex justify-content-between">
                  Filter
                  <Filter />
                </Container>
              }
              customCrossIcon={<Close />}
              noOverlay
              right
              width="100%"
              styles={menuStyles}
            >
              <Container className="d-flex" style={{ marginBottom: 20 }}>
                <Heading4>Filter by</Heading4>
              </Container>
              <BlogFilter
                filters={filters}
                onFilterUpdate={handleFilterUpdate}
              />
            </Menu>
          ) : (
            <Col className="d-flex">
              <Col md={1.5} className={styles.filterText}>
                Filter By
              </Col>
              <Col>
                <BlogFilter
                  filters={filters}
                  onFilterUpdate={handleFilterUpdate}
                  style={{ display: "flex" }}
                />
              </Col>
            </Col>
          )}

          <Row>
            <Pagination items={blogArticleCards} itemsPerPage={9} />
          </Row>
        </ScrollAnimation>
      </Section>
      <Section bgImg={section4Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>International Delivery Schedule</Heading1>
            <Heading4>
              Find out key bank holiday dates for each country we deliver to by
              clicking on the button below.
            </Heading4>
            <DownloadBtn variant="danger" downloadLink={BANK_HOLIDAYS}>
              Road Delivery Schedule (57kb)
            </DownloadBtn>
          </ScrollAnimation>
        </Col>
      </Section>
      <GetInTouch />
    </Trackable>
  );
};
